function castParallax() {

	window.addEventListener("scroll", function(event){

		var top = this.scrollY;

		var layers = document.getElementsByClassName("parallax");
		var layer, speed, yPos;
		for (var i = 0; i < layers.length; i++) {
			layer = layers[i];
			speed = layer.getAttribute('data-speed');
			yPos = -(top * speed / 100);
			layer.setAttribute('style', 'transform: translate3d(0px, ' + yPos + 'px, 0px)');
			
		}
	});


}

function dispelParallax() {
	document.getElementById("#nonparallax").style.display='block';
	document.getElementById("#parallax").style.display='none';
}

function startSite() {

	var platform = navigator.platform.toLowerCase();

	if ( platform.indexOf('ipad') !== -1  ||  platform.indexOf('iphone') !== -1 ) 
	{
		dispelParallax();
	}
	
	else if (platform.indexOf('win32') !== -1 || platform.indexOf('linux') !== -1)
	{
		castParallax();					
	}
	
	else
	{
		castParallax();
	}

}

window.addEventListener("load", function(){
    startSite();
});
