import React from "react";
import './Foot.scss';
import {DS} from '../socials/Socials';
    const today = new Date();
    const year = today.getFullYear();
function Footer() {
    return (
        <footer>
            <div className="footContent">
                <div className="copyright">

                    <p>© Dream Sloth Games {year}.
                    </p>
                </div> 
                <div className="footSocial">
                    <DS />
                </div> 
            </div>
        </footer>
        );
        
    }
        
    export default Footer;
 