import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faTwitter, faSteam, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import "./Socials.scss";

export const Steam = () => (
  <></>
  // <div className="Social">
  //   <a id="makeship" href="https://www.makeship.com/products/snowchaser" target="_blank" rel="noreferrer noopener">
  //     <span className="smTxt">Back us on</span>
  //     {
  //       //<FontAwesomeIcon icon={faSteam} size="4x"/> Add to Wishlist
  //     }
  //     <svg xmlns="http://www.w3.org/2000/svg" width="41" height="42" viewBox="0 0 41 42" fill="none">
  //       <g clipPath="url(#clip0_2238_712)">
  //         <path
  //           d="M20.5 0.5C9.17823 0.5 0 9.67864 0 21.0009C0 32.3232 9.17823 41.5018 20.5 41.5018C31.8218 41.5018 41 32.3232 41 21.0009C41 9.67864 31.8218 0.5 20.5 0.5ZM30.2491 29.9586H25.0504V21.1096L22.4005 29.9586H18.4006L15.7507 21.1096V29.9586H10.6772V12.1133H17.176L20.4761 22.9604L23.7761 12.1133H30.2491V29.9604V29.9586Z"
  //           fill="white"
  //         />
  //       </g>
  //       <defs>
  //         <clipPath id="clip0_2238_712">
  //           <rect width="41" height="41" fill="white" transform="translate(0 0.5)" />
  //         </clipPath>
  //       </defs>
  //     </svg>{" "}
  //     Makeship!
  //     <span className="tooltip">Claim your Snowchaser Plushie!</span>
  //   </a>
  // </div>
);

export const Showcase = () => (
  <div className="Social Showcase">
    <p>Join the Conversation</p>
    <a id="cord" href="https://discord.gg/duelyst" target="_blank" rel="noreferrer noopener">
      <FontAwesomeIcon icon={faDiscord} size="3x" />
    </a>
    <a id="twitter" href="https://twitter.com/Duelyst2" target="_blank" rel="noreferrer noopener">
      <FontAwesomeIcon icon={faTwitter} size="3x" />
    </a>
    <a id="youtube" href="https://www.youtube.com/c/Duelyst2" target="_blank" rel="noreferrer noopener">
      <FontAwesomeIcon icon={faYoutube} size="3x" />
    </a>
  </div>
);

export const DS = () => (
  <div className="Social">
    <a id="link" href="https://www.dreamslothgames.com/" target="_blank" rel="noreferrer noopener">
      <FontAwesomeIcon icon={faLink} size="2x" />
    </a>
    <a id="twitter" href="https://twitter.com/dreamslothgames" target="_blank" rel="noreferrer noopener">
      <FontAwesomeIcon icon={faTwitter} size="2x" />
    </a>
    <a id="steam" href="https://store.steampowered.com/search/?developer=Dream%20Sloth%20Games" target="_blank" rel="noreferrer noopener">
      <FontAwesomeIcon icon={faSteam} size="2x" />
    </a>
  </div>
);
