import React from 'react';
import Duelyst from'../../../assets/images/d2-nav-logo.png';
import './NavBar.scss';
function NavBar(){
    //Add Scroll class
    window.addEventListener("scroll", (event) => {
        const pageHeader = document.querySelector('.NavBar');
        if(window.scrollY !== 0){
            pageHeader.classList.add("scrolled");
        }else{
            if(pageHeader.classList.contains("scrolled")){
                pageHeader.classList.remove("scrolled");
            }
        }
      });

    
    React.useEffect(() => {        
        //Mobile Toggle
        const primaryNav = document.querySelector("#primary-nav");
        const navToggle = document.querySelector(".mobile-toggle");
        const hamBars = document.querySelectorAll('.bar');

        navToggle.addEventListener('click', () =>{
            
            const visibility = primaryNav.getAttribute('data-visible');
            
            setTimeout(() => {
                if(visibility === "false"){
                    primaryNav.setAttribute('data-visible', true);
                    navToggle.setAttribute('aria-expanded', true);
                    hamBars.forEach(bar => bar.classList.add('x'));
                }else{
                    primaryNav.setAttribute('data-visible', false);
                    navToggle.setAttribute('aria-expanded', false);
                    hamBars.forEach(bar => bar.classList.remove('x'));
                }
            }, "50");
        });
       //Smooth scroll for Nav Links 
      const navLinks = document.querySelectorAll('.NavBar a');

      navLinks.forEach(e => e.addEventListener("click", function(e) {
        if(!this.classList.contains('news')){
        e.preventDefault();
        }
        const destination = this.getAttribute('href');
        const link = document.querySelector(destination).offsetTop;

        window.scrollTo({
            top: link,
            left: 0,
            behavior: "smooth"
        });        

        if (primaryNav.getAttribute('data-visible') === "true"){
            navToggle.click();   
        }     
        
        }));  

    }, []);

    return(
        <header className="primary">
            <div className = "NavBar flex">
                <div className = "NavBar-Logo">
                    <a href="#root"><img src={Duelyst} alt="Duelyst II Logo"></img></a>
                </div>            
                <div className = "NavBar-Items">
                    <button className="mobile-toggle" aria-controls="primary-nav" aria-expanded="false">
                        <span className="sr-only">Menu</span>
                        <span className="bar" aria-hidden="true"></span>
                        <span className="bar" aria-hidden="true"></span>
                        <span className="bar" aria-hidden="true"></span>
                    </button>

                    <nav>
                        <ul id="primary-nav" data-visible="false">
                            <li><a href="#announcements">Play</a></li>
                            <li><a className="news" href="https://news.duelyst2.com/" target="blank" rel="noopener">News</a></li>
                            <li><a className="news" href="https://news.duelyst2.com/events-calendar/" target="blank" rel="noopener">Events</a></li>
                            <li><a className="news" href="https://news.duelyst2.com/category/lore/" target="blank" rel="noopener">Lore</a></li>
                            <li><a href="#about">About</a></li>
                            <li><a href="#faqs">FAQs</a></li>
                            <li><a href="#resources">Resources</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default NavBar;
