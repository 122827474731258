import React from 'react';
import './App.scss';
import Posts from './components/Blog/Posts';
import {Steam, Showcase} from './components/shared/socials/Socials';
import CountDown from './components/shared/countdown/Countdown';
import BGLayer from './assets/images/magaari/magaari_bg_layer.webp';
import BGLayerJpg from './assets/images/magaari/magaari_bg_layer.jpg';
import Layer1 from './assets/images/magaari/magaari_layer_1.webp';
import Layer1PNG from './assets/images/magaari/magaari_layer_1.png';
import Layer2 from './assets/images/magaari/magaari_layer_2.webp';
import Layer2PNG from './assets/images/magaari/magaari_layer_2.png';
import Layer3 from './assets/images/magaari/magaari_layer_3.webp';
import Layer3PNG from './assets/images/magaari/magaari_layer_3.png';
import Layer4 from './assets/images/magaari/magaari_layer_4.webp';
import Layer4PNG from './assets/images/magaari/magaari_layer_4.png';
import LogoWhite from './assets/images/LogoDuelyst2_Long_white-darker.png';
import highlight from './assets/images/highlight_blue.png';
import generalF3 from './assets/images/general_f3third2x-1.webp';
import generalF3PNG from './assets/images/general_f3third2x-p.png';
function App() {
  return (
<div id="page">
    <div className="bg" id="nonparallax"></div>
    <div className="bg" id="parallax">
    <div className="parallax" id="bg-0" data-speed="0">
            <picture>
                <source srcSet={BGLayer} type="image/webp" />
                <source srcSet={BGLayerJpg} type="image/jpeg" /> 
                <img src={BGLayerJpg} alt="Magarri Background"></img>
            </picture>
        </div>
        <div className="bgLayer parallax" id="bg-1" data-speed="8">
            <picture>
                <source srcSet={Layer1} type="image/webp" />
                <source srcSet={Layer1PNG} type="image/png" /> 
                <img src={Layer1PNG} alt="Magarri Background 1"></img>
            </picture>
        </div>
        <div className="bgLayer parallax" id="bg-2" data-speed="8">
            <picture>
                <source srcSet={Layer2} type="image/webp" />
                <source srcSet={Layer2PNG} type="image/png" /> 
                <img src={Layer2PNG} alt="Magarri Background 2"></img>
            </picture>
        </div>		
        <div className="bgLayer parallax" id="bg-3" data-speed="50">
            <picture>
                <source srcSet={Layer3} type="image/webp" />
                <source srcSet={Layer3PNG} type="image/png" /> 
                <img src={Layer3PNG} alt="Magarri Background 3"></img>
            </picture>
        </div>
        <div className="bgLayer parallax" id="bg-4" data-speed="0">
            <picture>
                <source srcSet={Layer4} type="image/webp" />
                <source srcSet={Layer4PNG} type="image/png" /> 
                <img src={Layer4PNG} alt="Magarri Background 4"></img>
            </picture>
        </div>		
        <div className="bgLayer parallax" id="bg-6" data-speed="65">
            <picture>
                <source srcSet={LogoWhite} type="image/webp" />
                <source srcSet={LogoWhite} type="image/png" /> 
                <img className="logo" src={LogoWhite} alt="Duelyst II Logo"></img>
            </picture>
            {
            <Steam/>
            }
                <div className="Kick">
                    <Showcase/>
                </div>
            </div>	
    </div>
    <main>
        <section id="announcements">
            <div className="inner-wrap">
                <CountDown/>
                <h1>Latest News</h1>
                <img className="hblue" loading="lazy" src={highlight} width="525" height="101" alt="Blue Highlight"></img>
                <Posts />
            </div>
        </section>
        <section id="about">
            <div className="inner-wrap">
                <h2>About</h2>
                <img className="hblue" loading="lazy" src={highlight} width="525" height="101" alt="Blue Highlight"></img>
                <div className="content flex">
                    <div className="intro">
                        <p><strong>DUELYST II</strong> is a free, fair, competitive tactics game. Build your collection of fully animated pixel art cards and put your skills to the test on the battlefield.</p>
                        <ul>
                            <li>Construct a deck from hundreds of unique cards - use minions, spells, and artifacts to discover new strategies and devastating combos.</li>
                            <li>Position your units and destroy the enemy General to claim victory in strategic, turn-based combat.</li>
                            <li>Choose from six powerful factions: Lyonar Kingdoms, Songhai Empire, Vetruvian Imperium, Abyssian Host, Magmar Aspects, and Vanar Kindred.</li>
                        </ul>
                    </div>
                    <div className="vid">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/vJuxghxYe1U" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </section> 
        <section id="faqs">
            <div className="inner-wrap">
                <h2>FAQs</h2>
                <img className="hblue" src={highlight} alt="Blue Highlight"></img>
                <div className="grid">
                    <div className="content">
                        <div className="questions">
                            <details>
                                <summary>How did Duelyst II come about?</summary>
                                <p>Our journey began in 2020, with a handful of fans coming together to create a spiritual successor to Duelyst. Since then, Duelyst II has grown into a full-fledged sequel - developed under Dream Sloth Games, our team now contains veteran devs from Duelyst, Rocket League, Warframe, and more.</p>
                                <p>Duelyst II is based off of the source code for Duelyst, which was released under a Creative Commons license is 2022.</p>
                            </details>                        
                            <details>
                                <summary>What are your plans for future content?</summary>
                                <p>A native mobile app, regular content releases, community events, sanctioned tournaments, client/UI revamp, and additional game modes are just some of the ways we'll be making Duelyst II bigger and better during and after open beta. You can look at our roadmap <a href="https://twitter.com/Duelyst2/status/1594433173122859008" target="_blank" rel="noreferrer noopener">here</a>.</p>
                                <p>As long-time fans ourselves, we've taken great care in preserving what made Duelyst unique among its peers, and beloved by its players. We've revamped the core set of cards from the original, and will release expansions tailored to the more expressive, strategic gameplay of Duelyst II. For returning Duelyst players, the most notable changes are drawing 2 cards instead of 1 at the end of each turn, and fewer random, "discoverable", and other high variance effects at high power levels.</p>
                            </details>                        
                            <details>
                                <summary>Can I play with my old Duelyst account?</summary>
                                <p>Within the Duelyst II client, we also offer Legacy Mode, in which you can play Duelyst as it was before shutting down. While none of your previous account data will carry over, all Legacy cards are free to use for everyone.</p>
                            </details>                       
                            <details>
                                <summary>How can I contact support?</summary>
                                <p>For all account, purchase, Kickstarter and other related issues, send an email to <a href="mailto:support@duelyst2.com?subject=Inquiry%20from%20D2%20Website">support@duelyst2.com</a>. If possible, please include your email and Steam ID associated with your purchase.</p>
                                <p>For other issues, try reaching out on <a href="https://discord.gg/duelyst" target="_blank" rel="noreferrer noopener">discord.gg/duelyst</a>. We actively monitor and participate in discussions there and have an active community of players that often answer questions before we do! Discord also contains a brief list of known issues and some clarifications for some interactions and is a great way to stay up-to-date with the game's development.</p>
                                <p>If you encounter a bug in the game and want to send us an email, please grab a log and the Game ID before closing the game! And remember to mention your in game name.</p>
                                <p>How to save the log: For both browser and steam clients, you can use <mark>ctrl+shift+i</mark> and go to the console tab, right click to save the log. </p>
                                <p>How to get the Game ID: To get the Game ID in your clipboard, use the backtick ` and then paste it to your nearest text editor.</p>
                            </details>                         
                        </div>
                    </div>
                    <div className="generalBG">
                    <picture>
                        <source srcSet={generalF3} type="image/webp" />
                        <source srcSet={generalF3PNG} type="image/jpeg" /> 
                        <img src={generalF3PNG} alt="General F3"></img>
                    </picture>
                    </div>
                    <p></p>
                </div>
            </div>
        </section>       
        <section id="resources">
            <div className="inner-wrap">
                <h2>Resources</h2>
                <img className="hblue" loading="lazy" src={highlight} width="525" height="101" alt="Blue Highlight"></img>
                <div className="grid">
                    {/*
                    <div className="core-set">
                        <div className="Home-Button">
                            <a href="https://duelspot.com/cards/" target="_blank" rel="nofollow noreferrer noopener">View Core Set <span>On Duelspot</span></a>
                        </div>
                    </div>*/
                    }
                    <div className="card-creator">
                        <div className="Home-Button">
                            <a className="singleLine" href="https://crystalwisp.duelyst2.com/" target="_blank" rel="nofollow noreferrer noopener">Card Creator</a>
                        </div>
                    </div>
                    <div className="press-kit">
                        <div className="Home-Button">
                            <a className="singleLine" href="https://presskit.dreamsloth.com/duelyst2" target="_blank" rel="nofollow noreferrer noopener">View Press Kit</a>
                        </div>
                    </div>
                </div>
                <h4>Having Trouble?</h4>
                <p><a href="mailto:support@duelyst2.com?subject=Bug%20Report%20from%20D2%20Website">Contact Support - support@duelyst2.com</a></p>
            </div>
        </section>
    </main>
</div>
  );
}

export default App;
